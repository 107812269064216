import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import store from '../store';
import { TOKEN_KEY } from 'constants/commonConstants';
import { SET_IS_LOGGED_IN } from '../constants/reduceConstants';
import { captureException, withScope } from '@sentry/react';
import {
  dateTimeFormat,
  formatDate,
  serializeError,
  enqueueWarningSnackbar
} from '../helpers/AppHelpers';
import data from '../../package.json';

const instances = {
  // New prod:
  production: {
    backendUrl: 'https://api-production.deployedresources.com/',
    url: 'https://drat2.deployedresources.com',
    socketUrl: 'wss://api-production.deployedresources.com/ws'
  },
  // Old prod:
  prod: {
    backendUrl: 'https://api.deployedresources.com/',
    url: 'https://cp.deployedresources.com',
    socketUrl: 'wss://api.deployedresources.com/ws'
  },
  stage: {
    backendUrl: 'https://api-stage.deployedresources.com/',
    url: 'https://cp-stage.deployedresources.com',
    socketUrl: 'wss://api-stage.deployedresources.com/ws'
  },
  dev: {
    backendUrl: 'https://api-dev.deployedresources.com/',
    url: 'https://cp-dev.deployedresources.com',
    socketUrl: 'wss://api-dev.deployedresources.com/ws'
  },
  qa: {
    backendUrl: 'https://api-qa.deployedresources.com/',
    url: 'https://cp-qa.deployedresources.com',
    socketUrl: 'wss://api-qa.deployedresources.com/ws'
  },
  local: {
    backendUrl: 'https://api-dev.deployedresources.com/',
    url: 'http://localhost:3000',
    socketUrl: 'wss://api-dev.deployedresources.com/ws'
  }
};
export const env = process.env.REACT_APP_ENVIRONMENT || 'local';

export const API_BASE_ADDRESS = instances[env].backendUrl;
export const SOCKET_API_BASE_ADDRESS = instances[env].socketUrl;
export const BASE_ADDRESS = instances[env].url;

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ignoredUrls = ['/User/Search', '/Asset/Search'];

const axiosInstance = axios.create({
  baseURL: API_BASE_ADDRESS,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const logoutFromSystem = () => {
  localStorage.removeItem(TOKEN_KEY);
  store.dispatch({
    type: SET_IS_LOGGED_IN,
    payload: false
  });
};

const isUrlIgnored = (url) => ignoredUrls.some((ignoredUrl) => url.includes(ignoredUrl));
const normalizeVersion = (version) => {
  const parts = version.split('.');
  return parts.slice(0, 3).join('.');
};

const reloadWithVersion = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.set('v', Date.now().toString());

  url.search = params.toString();
  window.location.replace(url.toString());
};

let hasUpgradeAlertShown = false;

export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);

  const inc = useCallback(() => setCounter((c) => c + 1), [setCounter]);
  const dec = useCallback(() => setCounter((c) => Math.max(c - 1, 0)), []);

  const logErrorToSentry = useCallback((error, status) => {
    let errObj = {};
    try {
      errObj = error?.request?.response ? JSON.parse(error.request.response) : {};
    } catch {
      errObj = { message: 'Failed to parse error response' };
    }

    withScope((scope) => {
      scope.setExtra('error', serializeError(errObj));
      scope.setExtra('env', process.env.REACT_APP_ENVIRONMENT || 'undefined');
      scope.setExtra('time', formatDate(new Date(), dateTimeFormat));
      scope.setTag('status', status);
      captureException(error);
    });
  }, []);

  const handleError = useCallback(
    async (error) => {
      const status = error?.response?.status;

      dec();

      switch (status) {
        case 401:
          logoutFromSystem();
          break;

        case 426:
          if (!hasUpgradeAlertShown) {
            hasUpgradeAlertShown = true;
            enqueueWarningSnackbar(
              'Your version is outdated. The page will refresh automatically.'
            );
            setTimeout(reloadWithVersion, 4000);
          }
          break;

        default:
          logErrorToSentry(error, status);
      }

      return Promise.reject(error);
    },
    [dec]
  );

  const handleRequest = useCallback((config) => {
    if (!isUrlIgnored(config.url)) inc();

    if (getToken()) {
      config.headers.authorization = `Bearer ${getToken() === 'undefined' ? '' : getToken()}`;
    }
    if (userTimeZone) {
      config.headers['Time-Zone'] = userTimeZone;
    }
    if (data.version) {
      config.headers['X-App-Version'] = normalizeVersion(data.version);
    }

    return config;
  }, []);

  useEffect(() => {
    const reqInterceptor = axiosInstance.interceptors.request.use(handleRequest, handleError);

    const resInterceptor = axiosInstance.interceptors.response.use((response) => {
      dec();
      return response;
    }, handleError);

    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [handleError, inc, dec]);

  return [counter > 0];
};

export default {
  get: (url, config) => axiosInstance.get(url, config),
  post: (url, data, config) => axiosInstance.post(url, data, config),
  put: (url, data, config) => axiosInstance.put(url, data, config),
  delete: (url, config) => axiosInstance.delete(url, config),
  patch: (url, data, config) => axiosInstance.patch(url, data, config)
};
