import React, { useState } from 'react';
import styles from './CreationForm.module.scss';

import ChipsContainer from './ChipsContainer';
import LooseItemLocations from './LooseItemLocations';

import { useLooseItemSelector, useLooseItemActions } from 'hooks/LooseItems';

import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import FormImagesUpload from 'components/FormComponents/FormImagesUpload/FormImagesUpload';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import EditLooseItemCategoryPopup from 'components/ParameterPopups/EditLooseItemCategoryPopup';
import InputSearchWithCreation from 'components/InputSearchWithCreation/InputSearchWithCreation';
import FieldsContainer from 'components/FormComponents/LooseItemFieldsContainer';
import CategoryOption from 'components/ParameterPopups/EditLooseItemCategoryPopup/CategoryOption';

import { Controller, useFormContext } from 'react-hook-form';
import { useUserConfig } from 'hooks/useUserConfig';
import { useParams } from 'react-router-dom';

import { getRandomId } from 'helpers/AppHelpers';
import clsx from 'clsx';

export default function CreationForm() {
  const { id } = useParams();
  const { isAdminUser, isOperationsManagerUser } = useUserConfig();
  const { watch, clearErrors, formState, control, getValues, setValue } = useFormContext();
  const { errors } = formState;

  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  const { locationList, categoryList, looseItemFields } = useLooseItemSelector();

  const { getCategoriesAction } = useLooseItemActions();

  const setValues = (name, value) => setValue(name, value, { shouldDirty: true });

  const onSelect = (name, value) => {
    name === 'location' && setValues('sublocation', null);
    setValues(name, value);
    errors[name]?.message && clearErrors(name);
  };

  const locationWatcher = watch('location');

  const handleEditOption = (option) => {
    setData(option);
    setOpen(true);
  };

  const handleUpdateCategory = ({ action, category }) => {
    getCategoriesAction();
    const isCategorySelected = !!getValues('looseItemCategories').find(
      ({ id }) => id === category.id
    )?.id;
    if (!isCategorySelected) return;

    const newCategoryValues = getValues('looseItemCategories').filter(
      ({ id }) => id !== category.id
    );
    if (action === 'UPDATE') {
      newCategoryValues.push(category);
    }
    setValues('looseItemCategories', newCategoryValues);
  };

  const selectOption = (name, value, selectionType, isInvalid) => {
    isInvalid && clearErrors(name);

    if (selectionType === 'remove-option') {
      setValue(
        name,
        [...getValues(name)].filter(({ name }) => name !== value.name),
        {
          shouldDirty: true
        }
      );
      return;
    }

    setValue(name, [...getValues(name), value], {
      shouldDirty: true
    });
  };

  const addItem = (value, options, creationType, name, isInvalid) => {
    isInvalid && clearErrors(name);
    setValue(
      name,
      [
        ...getValues(name),
        creationType === 'existed'
          ? value
          : { ...value, ...options, name: value?.name || '', keyId: getRandomId() }
      ],
      {
        shouldDirty: true
      }
    );
  };

  return (
    <section className={styles.wrapper}>
      {open && (
        <EditLooseItemCategoryPopup
          open={open}
          setOpen={setOpen}
          data={data}
          onUpdate={handleUpdateCategory}
        />
      )}
      <h2>General Details</h2>
      <div className={styles.form}>
        <div className={styles.form__row}>
          <label>Item name*</label>
          <FormInputText name="name" options={{ max: 40 }} />
        </div>
        {!id && (
          <>
            <div className={styles.form__row}>
              <label>Quantity*</label>
              <FormInputText name="quantity" options={{ focus: true, type: 'quantity' }} />
            </div>
            <div className={styles.form__row}>
              <label>BAA Quantity</label>
              <FormInputText name="byAheadAccount" options={{ focus: true, type: 'quantity' }} />
            </div>
            <div className={styles.form__row}>
              <label>Min Quantity</label>
              <FormInputText name="minimumQuantity" options={{ focus: true, type: 'quantity' }} />
            </div>
            <div className={styles.form__row}>
              <label>Location*</label>
              <FormSearchInput name="location" options={locationList || []} onSelect={onSelect} />
            </div>
            <div className={styles.form__row}>
              <label>Sublocation</label>
              <FormSearchInput
                clearable
                name="sublocation"
                options={locationWatcher?.sublocations || []}
                onSelect={onSelect}
                isDisabled={!locationWatcher?.id}
              />
            </div>
          </>
        )}
        <div
          className={clsx(
            styles.form__selector,
            !isAdminUser && !isOperationsManagerUser && styles.shorten
          )}>
          <label>Category*</label>
          <Controller
            name="looseItemCategories"
            control={control}
            render={({ field: { value, name }, fieldState: { error } }) => (
              <InputSearchWithCreation
                name={name}
                options={categoryList || []}
                value={value}
                onSelect={(name, value, type) => selectOption(name, value, type, !!error)}
                getOptionLabel={(option) => option.name}
                customLabel="name"
                max={100}
                isInvalid={!!error}
                invalidError={error?.message}
                tipLabel="Select from the list or write to add a new category"
                disableCreation={!isAdminUser && !isOperationsManagerUser}
                sameItemExistError="The category with such name already exists"
                onAdd={(value, options, creationType) =>
                  addItem(value, options, creationType, name, !!error)
                }
                OptionComponent={(option, selected) => (
                  <CategoryOption option={option} selected={selected} onEdit={handleEditOption} />
                )}
              />
            )}
          />
        </div>
        <ChipsContainer />
        <div className={styles.form__selector}>
          <label>Fields</label>
          <Controller
            name="customFields"
            control={control}
            render={({ field: { value, name } }) => (
              <InputSearchWithCreation
                name={name}
                options={looseItemFields || []}
                value={value}
                onSelect={selectOption}
                getOptionLabel={(option) => option.name}
                customLabel="name"
                max={50}
                onAdd={(value, options, creationType) =>
                  addItem(value, options, creationType, name)
                }
              />
            )}
          />
        </div>
        <div className={styles.fields}>
          <FieldsContainer />
        </div>

        <div className={styles.form__row}>
          <label id="photo_label">Photo</label>
          <FormImagesUpload name="resources" />
        </div>
        <div className={styles.form__description}>
          <label>Description*</label>
          <FormTextArea name="description" options={{ max: 65000 }} />
        </div>
        {id && <LooseItemLocations />}
      </div>
    </section>
  );
}
