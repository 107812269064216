import React from 'react';
import styles from './StyledLink.module.scss';

import { Link as RouterLink } from 'react-router-dom';

import clsx from 'clsx';

export const StyledLink = ({
  to,
  onClick,
  className,
  children,
  isRedirectAvailable = true,
  bold = false
}) => {
  const handleClick = (e) => {
    if (!isRedirectAvailable) {
      e.preventDefault();
      return;
    }

    onClick?.();
  };

  return (
    <RouterLink
      to={isRedirectAvailable && to ? to : '#'}
      className={clsx(
        styles.link,
        {
          [styles.bold]: bold,
          [styles.underline]: isRedirectAvailable
        },
        className && className
      )}
      onClick={handleClick}>
      {children}
    </RouterLink>
  );
};
