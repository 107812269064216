import React, { useEffect, useRef, useState } from 'react';
import styles from './BillOfLadingLogs.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { StyledButton, DownloadCSVButton, ImportCsvButton, BlueButton } from 'components/Buttons';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import ImportCsvPopup from 'components/ImportCsvPopup';
import { FileListPopper } from 'components/Poppers';
import SortableBlock from 'components/SortableBlock/SortableBlock';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import { IconButton, Table, TableBody, TableHead } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useBillOfLadingLogsActions, useBillOfLadingLogsSelector } from 'hooks/BillOfLadingLogs';
import { useHistory } from 'react-router-dom';
import useScrollManager from 'hooks/useScrollManager';

import {
  cloneObj,
  dateTimeFormat,
  fileDownload,
  formatDate,
  getUserFullName,
  isSuccessfulStatus,
  pluck,
  removeDuplicates
} from 'helpers/AppHelpers';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';

import { BILL_OF_LADING_LOG_EDIT_PATH, ROOT_PATH } from 'constants/routeConstants';
import { BOL_LOGS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { DELETE_BOL_LOG, DELETE_SELECTED_BOL_LOGS } from 'constants/dialogPopupsData';

import {
  generateChips,
  generateInitialQuery,
  parseQuery,
  removeChip,
  tableHeadConfig
} from './helpers';
import FilterModal from './Filter';

export function BillOfLadingLogs() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const tableRef = useRef(null);

  const { isConfigReceived, getTableLimit, isAllLimitEnabled, isAdminUser, isEntityCreatorUser } =
    useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, filterCriteria } = useBillOfLadingLogsSelector();
  const {
    getLogsAction,
    getFilterCriteriaAction,
    getCsvResourceAction,
    setFilterAction,
    removeLogsAction,
    getCsvTemplateAction,
    importCsvAction,
    clearStateAction
  } = useBillOfLadingLogsActions();

  const [tableState, setTableState] = useState([]);
  const [modalData, setModalData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openImportPopup, setOpenImportPopup] = useState(false);
  const [chips, setChips] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { filters, pagination, sortRules, responseReceived } = filter;

  const { saveScrollPosition, removeScrollPosition } = useScrollManager({
    tableKey: BOL_LOGS_TABLE_PARAMETER,
    isDataLoaded: responseReceived
  });

  const getLogs = (query, isAutoload) => {
    setFilterAction(query);

    const payload = cloneObj(query);
    payload.filters.createdUserList = [];

    getLogsAction(parseQuery(payload)).then((tableData) => {
      setLogs(tableData, query, isAutoload);
      !loadMoreFlag && setLoadMoreFlagAction(false);
    });
  };

  const setLogs = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data?.limit, page: data?.pageNumber, totalPages: data?.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      const resultFilter = generateInitialQuery({ filter, getTableLimit, isAllLimitEnabled });
      getLogs(resultFilter);

      const options = {
        ...criteria,
        users: filters?.createdUserList || []
      };
      setChips(generateChips(options, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    isMobile && history.push(ROOT_PATH);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = (value) => getLogs(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getLogs(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getLogs(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const changeLimit = () =>
    getLogs(getChangedByLimitFilter(filter, getTableLimit(BOL_LOGS_TABLE_PARAMETER)));
  const applyFilter = (values) => {
    getLogs(getChangedByApplyFilter(filter, values));

    const options = {
      ...filterCriteria,
      users: values?.createdUserList || []
    };
    setChips(generateChips(options, values));
  };

  const downloadCSV = () =>
    getCsvResourceAction(filter).then((file) => file?.link && fileDownload(file));

  const addBolLog = () => {
    removeScrollPosition();
    history.push(BILL_OF_LADING_LOG_EDIT_PATH);
  };
  const editBolLog = (id) => {
    history.push(`${BILL_OF_LADING_LOG_EDIT_PATH}/${id}`);
    saveScrollPosition();
  };

  const deleteRow = (deletedRowId) =>
    setModalData({ isOpened: true, ...DELETE_BOL_LOG, deletedRowIds: [deletedRowId] });

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    removeLogsAction({ ids: modalData.deletedRowIds }).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        getLogs(filter);
        closeModal();
        const filteredRows = selectedRowIds.filter((id) => !modalData.deletedRowIds.includes(id));
        setSelectedRowIds(filteredRows);
      }
    });
  };

  const openFilterModal = () => setOpenFilter(true);
  const openImportCsvPopup = () => setOpenImportPopup(true);

  const deleteChip = (chip) => applyFilter(removeChip(filters, chip));

  const importCsv = (resource) => importCsvAction({ resource });

  const isCheckboxSelected = (rowId) => selectedRowIds.includes(rowId);
  const handleCheckboxClick = (e, rowId) => {
    if (e.target.checked) {
      setSelectedRowIds(selectedRowIds.concat([rowId]));
    } else {
      setSelectedRowIds(selectedRowIds.filter((id) => id !== rowId));
    }
  };

  const isHeadCheckboxSelected = () => {
    const rowIds = pluck(tableState, 'id');
    const hasUnselectedIds = rowIds.some((id) => !selectedRowIds.includes(id));
    return !hasUnselectedIds;
  };

  const handleHeadCheckboxClick = (e) => {
    const rowIds = pluck(tableState, 'id');

    if (e.target.checked) {
      setSelectedRowIds(removeDuplicates([...selectedRowIds, ...rowIds]));
    } else {
      const filteredRows = selectedRowIds.filter((id) => !rowIds.includes(id));
      setSelectedRowIds(filteredRows);
    }
  };

  const deleteSelectedLogs = () =>
    setModalData({ isOpened: true, ...DELETE_SELECTED_BOL_LOGS, deletedRowIds: selectedRowIds });

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="BOL Shipped and Received Logs">
          <div className={styles.controls}>
            <ImportCsvButton label="Import BoL Log" onClick={openImportCsvPopup} />
            <DownloadCSVButton onClick={downloadCSV} />
            <StyledButton label="Add BoL Log" onClick={addBolLog} classes={styles.main_button} />
          </div>
        </SummaryPageHeader>
      </SummaryHeaderWrapper>

      <SummaryTableControls>
        <FilterWrapper>
          <FilterButton onClick={openFilterModal} isActive={openFilter || chips?.length} />
          <FilterChips chips={chips} onDelete={deleteChip} />
        </FilterWrapper>
        <div className={styles.controls}>
          {!!selectedRowIds?.length && isAdminUser && (
            <BlueButton
              label="Delete All"
              className={styles.delete_button}
              onClick={deleteSelectedLogs}
            />
          )}
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </div>
      </SummaryTableControls>

      <TableWrapper ref={tableRef} enableHorizontalScroll>
        <Table className={styles.logs_table}>
          <TableHead>
            <StyledTableRow>
              {isAdminUser && (
                <StyledTableCell className={styles.headCheckboxCell}>
                  {!!tableState?.length && (
                    <CustomCheckbox
                      sendEvent
                      value={isHeadCheckboxSelected()}
                      onChange={handleHeadCheckboxClick}
                    />
                  )}
                </StyledTableCell>
              )}
              {tableHeadConfig.map(({ id, label, parameter, isSortable }) => (
                <StyledTableCell className={styles.headCell} key={id}>
                  {isSortable ? (
                    <SortableBlock
                      label={label}
                      parameter={parameter}
                      sortRules={sortRules}
                      onSortApply={sortTable}
                    />
                  ) : (
                    <>{label}</>
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.logs_table_row}>
                  {isAdminUser && (
                    <StyledTableCell className={styles.checkboxCell}>
                      <CustomCheckbox
                        sendEvent
                        value={isCheckboxSelected(row.id)}
                        onChange={(e) => handleCheckboxClick(e, row.id)}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell className={styles.idCell}>{row?.id || ''}</StyledTableCell>
                  <StyledTableCell className={styles.typeCell}>
                    {row?.type?.displayName || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.locationCell}>
                    {row?.originLocation || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.destinationCell}>
                    {row?.destination || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.itemCell}>{row?.item || ''}</StyledTableCell>
                  <StyledTableCell className={styles.qtyCell}>{row?.qty || ''}</StyledTableCell>
                  <StyledTableCell className={styles.packageCell}>
                    {row?.package || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.palletCell}>
                    {row?.pallet || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.dateCell}>{row?.date || ''}</StyledTableCell>
                  <StyledTableCell className={styles.carrierCell}>
                    {row?.carrier || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.vendorCell}>
                    {row?.vendor || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.fileCell}>
                    <FileListPopper resources={row?.resources || []} />
                  </StyledTableCell>
                  <StyledTableCell className={styles.createdByCell}>
                    {getUserFullName(row.createdByUser)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.createdDateCell}>
                    {formatDate(row?.createdAtUtc, dateTimeFormat) || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.controlsCell}>
                    <div className={styles.row_controls}>
                      {(isAdminUser || isEntityCreatorUser(row.createdByUser.email)) && (
                        <IconButton
                          onClick={() => editBolLog(row.id)}
                          className={styles.icon_button}>
                          <CreateIcon />
                        </IconButton>
                      )}
                      {isAdminUser && (
                        <IconButton
                          onClick={() => deleteRow(row.id)}
                          className={styles.icon_button}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={BOL_LOGS_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}

      {openImportPopup && (
        <ImportCsvPopup
          open={openImportPopup}
          setOpen={setOpenImportPopup}
          getTemplate={getCsvTemplateAction}
          onApply={importCsv}
        />
      )}
    </SummaryWrapper>
  );
}
