import React, { useState } from 'react';
import styles from './Buttons.module.scss';

import TransparentButton from './TransparentButton';

import csvIcon from 'assets/images/csvIcon.svg';

import { wait } from 'helpers/AppHelpers';

export default function DownloadCSVButton({ onClick }) {
  const [isDisabled, setDisabled] = useState(false);

  const handleClick = () => {
    onClick();
    setDisabled(true);
    wait(20000).then(() => setDisabled(false));
  };

  return (
    <TransparentButton
      label="Download CSV File"
      className={styles.csvButton}
      onClick={handleClick}
      isDisabled={isDisabled}>
      <img src={csvIcon} alt="" />
    </TransparentButton>
  );
}
