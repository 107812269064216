import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import { FormDayPicker, FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { FormProvider, useForm } from 'react-hook-form';

import { useReportSelector } from 'hooks/ReportList';
import { useMobileViewport } from 'hooks/useMobileViewport';

import clsx from 'clsx';

export default function Filter({ open, setOpen, onApply, filter, reportType }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: {
      locationIds: [],
      locationJobNumberIds: [],
      projectIds: [],
      assignedUserIds: [],
      generalReportTemplateIds: [],
      dateFrom: null,
      dateTo: null,
      showAssigned: false,
      showNotSubmitted: false
    },
    mode: 'onChange'
  });
  const { getValues, reset, watch } = methods;

  const { filterCriteria = {} } = useReportSelector();

  const { locations, generalReportTemplate, projects, users } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = {
      ...getValues(),
      locationIds: [],
      locationJobNumberIds: [],
      projectIds: [],
      assignedUserIds: [],
      generalReportTemplateIds: [],
      dateFrom: null,
      dateTo: null,
      showAssigned: false,
      showNotSubmitted: false
    };
    reset(defaultState);
  };

  const dateFromWatcher = watch('dateFrom');
  const dateToWatcher = watch('dateTo');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              {reportType !== 'General' && (
                <div className={styles.form__block_cell}>
                  <FormLabel>Location</FormLabel>
                  <FormAutoMultiSelect
                    name="locationIds"
                    menuItems={locations || []}
                    options={{
                      label: 'siteCode',
                      disableByObjectTracker: true,
                      disableLabel: true
                    }}
                  />
                </div>
              )}
              {reportType !== 'General' && (
                <div className={styles.form__block_cell}>
                  <FormLabel>Job Number</FormLabel>
                  <FormAutoMultiSelect
                    name="locationJobNumberIds"
                    menuItems={locations || []}
                    options={{
                      label: 'locationJobNumber',
                      disableByObjectTracker: true,
                      disableLabel: true
                    }}
                  />
                </div>
              )}
              <div className={styles.form__block_cell}>
                <FormLabel>Report name</FormLabel>
                <FormAutoMultiSelect
                  name="generalReportTemplateIds"
                  menuItems={generalReportTemplate || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              {reportType === 'Location' && (
                <div className={styles.form__block_cell}>
                  <FormLabel>Project</FormLabel>
                  <FormAutoMultiSelect
                    name="projectIds"
                    menuItems={projects || []}
                    options={{
                      labelType: 'project',
                      disableByObjectTracker: true,
                      disableLabel: true
                    }}
                  />
                </div>
              )}
              {reportType === 'General' && (
                <div className={styles.form__block_cell}>
                  <FormLabel>Assignee</FormLabel>
                  <FormAutoMultiSelect
                    name="assignedUserIds"
                    menuItems={users || []}
                    options={{
                      labelType: 'user',
                      disableByObjectTracker: true,
                      disableLabel: true
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>From</FormLabel>
                <FormDayPicker name="dateFrom" max={dateToWatcher} />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>To</FormLabel>
                <FormDayPicker name="dateTo" min={dateFromWatcher} />
              </div>

              {reportType !== 'General' && (
                <div className={styles.form__block_cell}>
                  <FormLabel>Assignee</FormLabel>
                  <FormAutoMultiSelect
                    name="assignedUserIds"
                    menuItems={users || []}
                    options={{
                      labelType: 'user',
                      disableByObjectTracker: true,
                      disableLabel: true
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.form__block}>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Show assigned</FormLabel>
                <FormCheckbox name="showAssigned" />
              </div>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Show only not submitted</FormLabel>
                <FormCheckbox name="showNotSubmitted" />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
