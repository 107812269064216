import React, { useCallback, useEffect, useState } from 'react';

import { QRCodeGenerator, QRCodeScanner } from 'components/QR';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import NewVersion from 'components/ModalContents/NewVersion';

import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useHistory } from 'react-router-dom';
import { useUserConfig } from 'hooks/useUserConfig';

import { getUniqueID } from 'helpers/AppHelpers';
import { updateAppVersionShown } from 'helpers/UserConfigHelpers';

import {
  EXIT_WITHOUT_SAVING,
  LOGOUT,
  LOGOUT_TYPE,
  SHOW_VERSION_UPDATE_DIALOG,
  SHOW_VERSION_UPDATE_DIALOG_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { LOGIN_PATH, RELEASE_NOTES_PATH } from 'constants/routeConstants';

import SidebarWrapper from './SidebarWrapper';
import SearchBar from './SearchBar';
import List from './List';
import Controls from './Controls';

export function LeftSidebar() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);

  const [modalData, setModalData] = useState({});

  const { userConfig } = useUserConfig();
  const { unsavedFormData } = useCommonSelector();
  const {
    getUserConfigAction,
    updateUserConfigAction,
    setUnsavedFormDataAction,
    setSessionIdAction,
    logoutAction
  } = useCommonActions();

  useEffect(() => {
    getUserConfigAction().then(({ userId, appVersion }) => {
      if (!userId) return;

      const { isShownWeb, releaseDate, version } = appVersion;
      if (isShownWeb) return;

      setModalData({
        ...SHOW_VERSION_UPDATE_DIALOG,
        content: <NewVersion date={releaseDate} version={version} />,
        isOpened: true
      });
    });
  }, []);

  useEffect(() => {
    setSessionIdAction(getUniqueID());
  }, []);

  const scanQr = (data) => {
    const url =
      '/' +
      data
        .split('/')
        .filter((_, i) => i !== 0 && i !== 1 && i !== 2)
        .join('/');

    if (unsavedFormData) {
      setModalData({ ...EXIT_WITHOUT_SAVING, url });
    } else {
      history.push(url);
      setOpen(false);
    }
  };

  const toggleBar = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleOpenScanner = useCallback(() => {
    setOpenScanner(true);
    setOpen(false);
  }, []);

  const generateQr = useCallback(() => {
    setOpenQR(true);
    setOpen(false);
  }, []);

  const handleLogoutClick = useCallback(() => setModalData({ ...LOGOUT, isOpened: true }), []);

  const markUserAsNotified = () => updateUserConfigAction(updateAppVersionShown(userConfig, true));

  const closeModal = () => setModalData({});

  const agreeModal = () => {
    switch (modalData.type) {
      case WITHOUT_SAVING_TYPE:
        setUnsavedFormDataAction(false);
        history.push(modalData.url);
        break;
      case LOGOUT_TYPE:
        history.push(LOGIN_PATH);
        logoutAction();
        break;
      case SHOW_VERSION_UPDATE_DIALOG_TYPE:
        markUserAsNotified();
        break;
      default:
        break;
    }
    closeModal();
  };

  const dismissModal = () => {
    if (modalData?.type === SHOW_VERSION_UPDATE_DIALOG_TYPE) {
      markUserAsNotified();
      history.push(RELEASE_NOTES_PATH);
    }

    closeModal();
  };

  const handleLinkClick = useCallback(
    (url) => {
      if (unsavedFormData) {
        setModalData({ ...EXIT_WITHOUT_SAVING, url });
      } else {
        history.push(url);
        setOpen(false);
      }
    },
    [history, unsavedFormData]
  );

  return (
    <>
      <SidebarWrapper open={open} setOpen={setOpen}>
        <SearchBar sidebarOpen={open} openScanner={handleOpenScanner} onToggle={toggleBar} />

        <List sidebarOpen={open} onLinkClick={handleLinkClick} />

        {open && <Controls onQrClick={generateQr} onLogout={handleLogoutClick} />}
      </SidebarWrapper>

      {openScanner && <QRCodeScanner open={openScanner} setOpen={setOpenScanner} onScan={scanQr} />}

      {openQR && <QRCodeGenerator open={openQR} setOpen={setOpenQR} />}

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={dismissModal} />
    </>
  );
}
