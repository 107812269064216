import React from 'react';
import styles from './NewVersion.module.scss';
import { formatDate } from 'helpers/AppHelpers';

export function NewVersion({ version, date }) {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__header}>
        A new version of the app {version} has been available since {date ? formatDate(date) : ''}!
      </div>
      <div className={styles.modal__content}>
        {`Tap "OK" or "View Details" to avoid seeing this notification again.`}
      </div>
    </div>
  );
}
