import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  SearchField,
  SimplePagination,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper
} from 'components/SummaryComponents';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import { SeverityCalculations } from 'components/Counting';
import {
  AssetFieldCell,
  AssetLocationCell,
  AssetShortNoteCell,
  AssetStatusCell,
  DataTable,
  AssetCell,
  SortableHeader
} from 'components/TableComponents';
import EditCellPopup from 'components/EditCellPopup';
import { DownloadCSVButton } from 'components/Buttons';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import usePersistedColumnWidths from 'hooks/usePersistedColumnWidths';
import useScrollManager from 'hooks/useScrollManager';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import {
  getAssetLocationTooltip,
  getAssetRentalTooltip,
  getAssetShortNoteTooltip,
  getAssetSublocationTooltip,
  getAssetTypeTooltip,
  getAssetFieldTooltip,
  getAssetCellTooltip,
  getAssetStatusTooltip
} from 'helpers/TooltipFunctions';
import { fileDownload, pluck } from 'helpers/AppHelpers';

import { ASSET_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';

import FilterModal from './components/Filter';
import Actions from './components/Actions';
import {
  useAssetSummaryParams,
  generateChips,
  generateInitialQuery,
  removeChip,
  parseQuery,
  getEditPopupOptions,
  getShortNoteOptions,
  getAssetFieldOptions,
  getRentalOptions,
  columnSizesConfig
} from './helpers';

import { createColumnHelper } from '@tanstack/react-table';
import { useCommonActions } from 'hooks/Common';

const columnHelper = createColumnHelper();

export default function AssetsList() {
  const tableRef = useRef();
  const isMobile = useMobileViewport();
  const urlParams = useAssetSummaryParams();
  const { calculateColumnWidths } = usePersistedColumnWidths(ASSET_LIST_TABLE_PARAMETER);

  const { isConfigReceived, getTableLimit, isAllLimitEnabled } = useUserConfig();

  const { setTopPositionAction } = useCommonActions();
  const { counting, filterCriteria, filter } = useAssetSelector();
  const {
    getAssetsAction,
    getCountingAction,
    getFilterCriteriaAction,
    setFilterAction,
    updateAssetFromListAction,
    updateAssetFieldAction,
    getSummaryCsvResourceAction,
    clearStateAction
  } = useAssetActions();

  const [openFilter, setOpenFilter] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);

  const [chips, setChips] = useState([]);
  const [tableState, setTableState] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});

  const [editorOptions, setEditorOptions] = useState({});

  const { filters, pagination, sortRules, responseReceived, columns } = filter;

  const scrollTableToPosition = ({ table, container }) => {
    setTopPositionAction(container);
    tableRef.current.scrollTo({ top: table, behavior: 'smooth' });
  };

  const { saveScrollPosition } = useScrollManager({
    tableKey: ASSET_LIST_TABLE_PARAMETER,
    isDataLoaded: responseReceived,
    onScroll: scrollTableToPosition
  });

  const saveScroll = () => saveScrollPosition({ table: tableRef.current?.scrollTop });

  const getAssets = useCallback(
    (query, { isAutoload = false, shouldSkipReceivingCounting = false } = {}) => {
      setFilterAction(query);

      getAssetsAction(parseQuery(query)).then((tableData) => {
        !isAutoload && !shouldSkipReceivingCounting && getCountingAction(query.filters);
        setAssets(tableData, query, isAutoload);
      });
    },
    [setFilterAction, getAssetsAction, getCountingAction]
  );

  const setAssets = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      const q = { getTableLimit, isAllLimitEnabled, filter, ...urlParams };
      const resultFilter = generateInitialQuery(q);
      getAssets(resultFilter);
      setChips(generateChips(criteria, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getAssets(getChangedBySearchFilter(filter, value)),
    [filter, getAssets]
  );
  const sortTable = useCallback(
    (rowName) => getAssets(getChangedBySortFilter(filter, rowName)),
    [filter, getAssets]
  );
  const applyFilter = useCallback(
    (values) => {
      getAssets(getChangedByApplyFilter(filter, values));
      setChips(generateChips(filterCriteria, values));
    },
    [filter, getAssets, filterCriteria]
  );
  const refreshTable = useCallback(() => getAssets(filter), [filter, getAssets]);
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip, filterCriteria)),
    [applyFilter, filters, filterCriteria]
  );
  const changeLimit = useCallback(
    () => getAssets(getChangedByLimitFilter(filter, getTableLimit(ASSET_LIST_TABLE_PARAMETER))),
    [filter, getTableLimit, getAssets]
  );
  const changePage = useCallback(
    (page, param) =>
      getAssets(getChangedByPageFilter(filter, page), { isAutoload: param === 'AUTOLOAD' }),
    [filter, getAssets]
  );
  const addColumn = useCallback(
    (assetFields) => {
      const filterValue = {
        ...filter,
        filters: { ...filter.filters, assetFieldIds: pluck(assetFields, 'id') },
        columns: assetFields
      };
      getAssets(filterValue, { shouldSkipReceivingCounting: true });
    },
    [applyFilter, filter]
  );

  const loadMore = useCallback(
    () => changePage(pagination.page + 1, 'AUTOLOAD'),
    [pagination, changePage]
  );

  const openFilterModal = useCallback(() => setOpenFilter(true), []);

  const isAssetAvailable = useCallback(
    (asset) => asset?.rental?.id === 1 && asset?.isInPicklist?.name === 'No' && !asset?.deleted,
    []
  );

  const Filter = () => (
    <FilterWrapper>
      <FilterButton onClick={openFilterModal} isActive={openFilter || chips?.length} />
      {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
    </FilterWrapper>
  );

  const isHeadCheckboxSelected = useMemo(() => {
    const available = tableState.filter(isAssetAvailable);

    if (!available?.length) return false;

    const ids = available.map(({ id }) => id);
    const selectedIds = selectedRows.map(({ id }) => id);
    return !ids.some((id) => !selectedIds.includes(id));
  }, [tableState, selectedRows]);

  const isHeadCheckboxDisabled = useMemo(() => {
    return !tableState.some(isAssetAvailable);
  }, [tableState]);

  const handleSuccessSave = useCallback((updatedRow, index) => {
    setTableState((prevState) => prevState.map((row, i) => (i === index ? updatedRow : row)));
  }, []);

  const saveCellValue = useCallback(
    (data, index) => {
      const updatedRow = { ...tableState[index], ...data };

      updateAssetFromListAction(updatedRow).then((res) => {
        if (!res.id) return;
        handleSuccessSave(updatedRow, index);
      });
    },
    [tableState, updateAssetFromListAction, handleSuccessSave]
  );

  const editShortNote = useCallback(
    (asset, index, onClose) => {
      setEditorOptions({
        popup: getEditPopupOptions('Edit Short Note'),
        field: getShortNoteOptions({ value: asset.shortNote, index }),
        onSave: saveCellValue,
        onClose
      });
      setOpenEditor(true);
    },
    [saveCellValue]
  );

  const isFieldEditable = (assetPrefixId, { id, type, prefixIds = [] }) =>
    ['String', 'Int'].includes(type) && id > 0 && prefixIds.includes(assetPrefixId);

  const saveFieldValue = useCallback(
    (data, index, options) => {
      const payload = {
        assets: [tableState[index]],
        assetFields: [{ assetField: options.field, ...data }]
      };

      updateAssetFieldAction(payload).then((res) => {
        if (!res?.[0]?.id) return;
        handleSuccessSave(res[0], index);
      });
    },
    [tableState, updateAssetFieldAction, handleSuccessSave]
  );

  const editAssetField = useCallback(
    (asset, index, onClose, column) => {
      const findFn = ({ assetField }) => assetField.id === column.id;
      const obj = asset.assetFields.find(findFn);

      const fieldType = obj.assetField.type;

      setEditorOptions({
        popup: getEditPopupOptions('Edit Asset Field'),
        field: getAssetFieldOptions({
          type: ['Int', 'Float'].includes(fieldType) ? 'quantity' : 'default',
          max: fieldType === 'String' ? 255 : null,
          value: obj.value,
          index
        }),
        onSave: saveFieldValue,
        onClose,
        customOptions: { field: obj.assetField }
      });
      setOpenEditor(true);
    },
    [saveFieldValue]
  );

  const editRental = useCallback(
    (asset, index, onClose) => {
      setEditorOptions({
        popup: getEditPopupOptions('Edit Rental'),
        field: getRentalOptions({
          list: filterCriteria?.rentals || [],
          value: asset.rental,
          index
        }),
        onSave: saveCellValue,
        onClose
      });
      setOpenEditor(true);
    },
    [saveCellValue]
  );

  const tableColumns = useMemo(() => {
    const getCustomColumnId = (id) => `custom_id_${id}`;
    const customColumnNames = columns.map(({ id }) => getCustomColumnId(id));
    const columnWidths = calculateColumnWidths(columnSizesConfig, customColumnNames);

    const defaultColumns = [
      columnHelper.accessor('drCode', {
        id: 'drCode',
        header: ({ table }) => (
          <SortableHeader
            canSelect
            isSelected={isHeadCheckboxSelected}
            shouldDisableCheckbox={isHeadCheckboxDisabled}
            onChange={table.getToggleAllRowsSelectedHandler()}
            displayName="DR-ID"
            sortKey="drCode"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: ({ row }) => (
          <AssetCell
            canSelect={row.getCanSelect()}
            isSelected={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            asset={row.original}
            onLinkClick={saveScroll}
          />
        ),
        tooltipValueGetter: getAssetCellTooltip,
        sticky: !isMobile,
        minSize: 75,
        size: columnWidths.drCode
      }),
      columnHelper.accessor('severity', {
        id: 'severity',
        header: () => (
          <SortableHeader
            displayName="Op."
            sortKey="severity.name"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: ({ row }) => <AssetStatusCell asset={row.original} />,
        tooltipValueGetter: getAssetStatusTooltip,
        size: columnWidths.severity
      }),
      columnHelper.accessor((row) => row.lastLocationHistory?.location, {
        id: 'location',
        header: () => (
          <SortableHeader
            displayName="Location"
            sortKey="lastLocationHistory.location.siteCode"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: ({ row }) => <AssetLocationCell asset={row.original} />,
        tooltipValueGetter: getAssetLocationTooltip,
        size: columnWidths.location
      }),
      columnHelper.accessor((row) => row.lastLocationHistory?.sublocation, {
        id: 'sublocation',
        header: () => (
          <SortableHeader
            displayName="Sublocation"
            sortKey="lastLocationHistory.sublocation.name"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: ({ getValue }) => getValue()?.name || '',
        tooltipValueGetter: getAssetSublocationTooltip,
        size: columnWidths.sublocation
      }),
      columnHelper.accessor('rental', {
        id: 'rental',
        header: () => (
          <SortableHeader
            displayName="Rental"
            sortKey="rental.rentalStatus.name"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: ({ getValue }) => getValue()?.name || '',
        tooltipValueGetter: getAssetRentalTooltip,
        editable: (asset) => asset.isInPicklist?.name === 'No',
        onEditClick: editRental,
        size: columnWidths.rental
      }),
      columnHelper.accessor('assetPrefix', {
        id: 'assetPrefix',
        header: () => (
          <SortableHeader
            displayName="Type"
            sortKey="assetPrefix.prefixType.name"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: ({ getValue }) => getValue()?.prefixType?.name || '',
        tooltipValueGetter: getAssetTypeTooltip,
        size: columnWidths.assetPrefix
      }),
      columnHelper.accessor('shortNote', {
        id: 'shortNote',
        header: () => (
          <SortableHeader
            displayName="Short Note"
            sortKey="shortNote"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        editable: true,
        onEditClick: editShortNote,
        cell: ({ getValue }) => <AssetShortNoteCell note={getValue()} />,
        tooltipValueGetter: getAssetShortNoteTooltip,
        size: columnWidths.shortNote
      })
    ];

    const customCols = columns.map(({ id, name, displayName, type, prefixIds, isDefault }) =>
      columnHelper.accessor('assetFields', {
        id: getCustomColumnId(id),
        header: () => (
          <SortableHeader
            displayName={displayName}
            sortKey={isDefault ? `assetFields.${name}` : `assetCustomFields.${name}`}
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: ({ getValue }) => <AssetFieldCell assetFields={getValue() || []} columnId={id} />,
        size: columnWidths[getCustomColumnId(id)],
        editable: (asset) => isFieldEditable(asset.assetPrefix.id, { id, type, prefixIds }),
        onEditClick: (asset, index, onClose) =>
          editAssetField(asset, index, onClose, { id, displayName }),
        tooltipValueGetter: (asset) => getAssetFieldTooltip(asset?.assetFields || [], id)
      })
    );

    return [...defaultColumns, ...customCols];
  }, [
    selectedRows,
    columns,
    isHeadCheckboxSelected,
    sortTable,
    editShortNote,
    editRental,
    isFieldEditable,
    editAssetField
  ]);

  const downloadCSV = () =>
    getSummaryCsvResourceAction(filter).then((file) => file?.link && fileDownload(file));

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Asset Summary">
          {!isMobile && <DownloadCSVButton onClick={downloadCSV} />}
        </SummaryPageHeader>
        {isMobile && (
          <SummaryTableControls>
            <Filter />
            <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
          </SummaryTableControls>
        )}
      </SummaryHeaderWrapper>

      <SeverityCalculations counting={counting} />

      {!isMobile && (
        <SummaryTableControls>
          <Filter />
          <Actions
            onRefresh={refreshTable}
            onAddColumn={addColumn}
            isTableFiltered={!!chips?.length || !!filters?.searchQuery}
            selectedAssets={selectedRows}>
            <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
          </Actions>
        </SummaryTableControls>
      )}

      {isMobile && (
        <Actions onAddColumn={addColumn} onRefresh={refreshTable} selectedAssets={selectedRows} />
      )}

      <DataTable
        tableRef={tableRef}
        columns={tableColumns}
        data={tableState}
        isLoading={!responseReceived}
        isNoMatches={!!chips?.length || !!filters?.searchQuery}
        isMorePagesAvailable={pagination?.page < pagination?.totalPages}
        loadMore={loadMore}
        tableKey={ASSET_LIST_TABLE_PARAMETER}
        setSelectedRows={setSelectedRows}
        enableRowSelection={(row) => isAssetAvailable(row.original)}
      />

      <SimplePagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={ASSET_LIST_TABLE_PARAMETER}
      />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}

      {openEditor && (
        <EditCellPopup open={openEditor} setOpen={setOpenEditor} options={editorOptions} />
      )}
    </SummaryWrapper>
  );
}
