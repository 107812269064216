import React from 'react';
import styles from './StyledTooltip.module.scss';
import { Tooltip } from 'react-tooltip';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { TOOLTIP_ID } from 'constants/commonConstants';

export function StyledTooltip() {
  const isMobile = useMobileViewport();

  return (
    <Tooltip
      id={TOOLTIP_ID}
      offset={-4}
      className={styles.tooltip}
      noArrow
      place="bottom"
      delayShow={500}
      hidden={isMobile}
    />
  );
}
