import { LOOSE_ITEMS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj, pluck } from 'helpers/AppHelpers';
import { handleBooleanFilter, handleIdsFilter, handleNamesFilter } from 'helpers/ChipGenerator';
import { useSearchParams } from 'hooks/useSearchParams';

const defaultFilter = {
  filters: {
    searchQuery: '',
    assetIds: [],
    assetList: [],
    categoryIds: [],
    looseItemIds: [],
    locationIds: [],
    sublocationIds: [],
    locationJobNumberIds: [],
    statusNames: [],
    showWithBaa: false,
    showWithReorderTrigger: false
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const useSummaryParams = () =>
  useSearchParams([
    'searchQuery',
    'locationIds',
    'categoryIds',
    'showWithBaa',
    'showWithReorderTrigger',
    'looseItemIds',
    'statusNames',
    'locationJobNumberIds'
  ]);

const parseParams = (params) => {
  const hasParams = Object.values(params).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value !== undefined && value !== null && value !== false;
  });

  if (!hasParams) return {};

  const parsedParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (key.endsWith('Ids')) {
      if (Array.isArray(value)) {
        acc[key] = value.map(Number);
      } else if (value !== undefined) {
        acc[key] = [Number(value)];
      }
    } else if (key.startsWith('show')) {
      if (value === 'true') {
        acc[key] = true;
      }
    } else if (key === 'statusNames') {
      if (Array.isArray(value)) {
        acc[key] = value;
      } else if (value !== undefined) {
        acc[key] = [value];
      }
    } else if (key === 'searchQuery') {
      if (value !== undefined && value !== '') {
        acc[key] = value;
      }
    }

    return acc;
  }, {});
  return parsedParams;
};

const generateInitialQuery = ({ getTableLimit, isAllLimitEnabled, filter, urlParams }) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(LOOSE_ITEMS_TABLE_PARAMETER);

  const initialQuery = {
    filters: { ...defaultFilter.filters },
    pagination: { limit, page: defaultPage },
    sortRules: filter?.sortRules || defaultFilter.sortRules,
    responseReceived: false
  };

  const setFilters = (customFilters) => ({
    ...initialQuery,
    filters: { ...initialQuery.filters, ...customFilters }
  });

  if (urlParams?.searchQuery) {
    return setFilters({ searchQuery: urlParams.searchQuery });
  }

  const parsedParams = parseParams(urlParams);
  if (Object.keys(parsedParams).length > 0) {
    return setFilters(parsedParams);
  }

  const isAll = isAllLimitEnabled(LOOSE_ITEMS_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    ...initialQuery,
    filters: { ...initialQuery.filters, ...filter?.filters },
    pagination: { limit, page }
  };
};

const generateChips = (criteria, filters = {}) => {
  const { locations, looseItemCategories, looseItems, statuses, sublocations, assets } = criteria;

  let newChips = [];

  const filterConfigs = [
    { options: locations, getLabel: (el) => el.siteCode, key: 'locationIds' },
    { options: locations, getLabel: (el) => el.locationJobNumber, key: 'locationJobNumberIds' },
    { options: looseItemCategories, getLabel: (el) => el.name, key: 'categoryIds' },
    { options: looseItems, getLabel: (el) => el.name, key: 'looseItemIds' },
    { options: sublocations, getLabel: (el) => el.name, key: 'sublocationIds' },
    { options: assets, getLabel: (el) => el.drCode, key: 'assetIds' }
  ];

  filterConfigs.forEach(({ options, getLabel, key }) => {
    if (filters?.[key]?.length) {
      newChips = newChips.concat(handleIdsFilter(filters[key], options, getLabel, key));
    }
  });

  if (filters?.statusNames?.length) {
    newChips = newChips.concat(handleNamesFilter(filters.statusNames, statuses, 'statusNames'));
  }

  if (filters?.showWithBaa) {
    newChips = newChips.concat(handleBooleanFilter('showWithBaa', 'Show with BAA qty'));
  }
  if (filters?.showWithReorderTrigger) {
    newChips = newChips.concat(
      handleBooleanFilter('showWithReorderTrigger', 'Show with reorder trigger')
    );
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip, criteria) => {
  const { locations } = criteria;
  const newFilters = cloneObj(filters);
  const { key, itemName, itemId } = deletedChip;

  switch (key) {
    case 'showWithBaa':
    case 'showWithReorderTrigger':
      newFilters[key] = false;
      break;
    case 'statusNames':
      newFilters[key] = newFilters[key].filter((name) => name !== itemName);
      break;
    case 'assetIds':
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      newFilters.assetList = newFilters.assetList.filter(({ id }) => id !== itemId);
      break;
    case 'locationIds': {
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      const sublocations = locations.find(({ id }) => id === itemId)?.sublocations;
      const subIds = pluck(sublocations, 'id');
      if (subIds?.length) {
        newFilters.sublocationIds = newFilters.sublocationIds.filter((id) => !subIds.includes(id));
      }
      break;
    }
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = [
    'searchQuery',
    'assetIds',
    'categoryIds',
    'looseItemIds',
    'locationIds',
    'sublocationIds',
    'locationJobNumberIds',
    'statusNames',
    'showWithBaa',
    'showWithReorderTrigger'
  ];

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  return payload;
};

const generateSelectedRowObject = ({ locationLooseItemId, quantity = 0, byAheadAccount = 0 }) => ({
  id: locationLooseItemId,
  quantity,
  byAheadAccount
});

export {
  defaultFilter,
  useSummaryParams,
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery,
  generateSelectedRowObject
};
