import React, { useEffect, useState } from 'react';
import styles from './ReleaseNotes.module.scss';

import {
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryWrapper
} from 'components/SummaryComponents';

import { useUserConfig } from 'hooks/useUserConfig';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getAppVersions } from 'actions/appVersionActions';

import { HOME_PATH } from 'constants/routeConstants';

import Release from './Release';

export function ReleaseNotes() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [versions, setVersions] = useState([]);

  const { isConfigReceived } = useUserConfig();

  useEffect(async () => {
    if (!isConfigReceived) return;

    const response = await dispatch(getAppVersions());

    if (response?.length) {
      setVersions(response);
    } else {
      history.push(HOME_PATH);
    }
  }, [isConfigReceived]);

  if (!isConfigReceived) return null;

  return (
    <SummaryWrapper className={styles.wrapper}>
      <SummaryHeaderWrapper className={styles.header}>
        <SummaryPageHeader title="Release Notes" />
      </SummaryHeaderWrapper>

      {versions.map((v) => (
        <Release key={v.id} data={v} />
      ))}
    </SummaryWrapper>
  );
}
