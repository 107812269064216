import React from 'react';
import styles from './Buttons.module.scss';

import { Button } from '@material-ui/core';

import clsx from 'clsx';

export default function TransparentButton({ label, onClick, className, isDisabled, children }) {
  return (
    <Button
      className={clsx(className, styles.transparent_button, isDisabled && styles.disabled)}
      onClick={onClick}>
      {children}
      <span>{label}</span>
    </Button>
  );
}
