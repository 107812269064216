import { useSelector } from 'react-redux';
import { selectUserConfig } from '../pages/commonSelectors';
import {
  isAdminUser,
  isFieldTechUser,
  isLocationAssignedToUser,
  isOperationsManagerUser,
  isPersonnelManagerUser,
  isTeamMemberUser
} from '../helpers/AppHelpers';
import { useMobileViewport } from './useMobileViewport';

export const useUserConfig = () => {
  const isMobile = useMobileViewport();

  const userConfig = useSelector(selectUserConfig());
  const {
    userRole,
    email,
    locations,
    teamMember,
    teamMemberProjectsExist,
    userId,
    firstName,
    lastName,
    tables,
    departments,
    appVersion
  } = userConfig;

  const checkUserRole = (role) => {
    if (role === 'Admin') return isAdminUser(userRole);
    if (role === 'OperationalManager') return isOperationsManagerUser(userRole);
    if (role === 'TeamMember') return isTeamMemberUser(userRole);
    if (role === 'PersonnelManager') return isPersonnelManagerUser(userRole);
    if (role === 'FieldTech') return isFieldTechUser(userRole);
  };

  const getUpdatedByTablesConfigCopy = (param, limit, isAll) => {
    const userConfigCopy = { ...userConfig };
    const tableIndex = userConfigCopy['tables'].findIndex(({ name }) => name === param);
    userConfigCopy['tables'][tableIndex].fetchRowsCount = limit;
    userConfigCopy['tables'][tableIndex].isAll = !!isAll;
    return userConfigCopy;
  };

  const isAllLimitEnabled = (tableName) => {
    return !!tables?.find(({ name }) => name === tableName)?.isAll;
  };

  return {
    userConfig,
    appVersion,
    isAdminUser: isAdminUser(userRole),
    isOperationsManagerUser: isOperationsManagerUser(userRole),
    isBasicRoleUser: isTeamMemberUser(userRole),
    isPersonnelManagerUser: isPersonnelManagerUser(userRole),
    isFieldTechUser: isFieldTechUser(userRole),
    isEntityCreatorUser: (entityEmail) => entityEmail === email,
    isLocationInConfig: (locationId) => isLocationAssignedToUser(locations, locationId),
    isUser: (role) => checkUserRole(role),
    isTeamMemberUser: !!teamMember?.id,
    isTeamMemberAttachedToTheProjects: !!teamMemberProjectsExist,
    userId,
    userEmail: email,
    userFirstName: firstName,
    userLastName: lastName,
    teamMemberConfigData: teamMember,
    teamMemberId: teamMember?.id,
    getTableLimit: (tableName) => {
      const limitFromConfig = tables?.find(({ name }) => name === tableName)?.fetchRowsCount || 10;
      return limitFromConfig === 100 && isMobile ? 50 : limitFromConfig;
    },
    isConfigReceived: !!userId,
    getUpdatedByTablesConfigCopy: getUpdatedByTablesConfigCopy,
    isUserAssignedToTheDepartments: !!departments?.length,
    isAllLimitEnabled: isAllLimitEnabled,
    userRoleId: userRole?.id,
    isShownWeb: appVersion?.isShownWeb || false
  };
};
