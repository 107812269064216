import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import styles from './TicketSummary.module.scss';

import {
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  SimplePagination
} from 'components/SummaryComponents';
import { StyledButton, DownloadCSVButton } from 'components/Buttons';
import { Indicator, SeverityCalculations } from 'components/Counting';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import StyledLink from 'components/StyledLink';
import { DataTable, SimpleHeader, SortableHeader } from 'components/TableComponents';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useTicketActions, useTicketSelector } from 'hooks/Ticket';
import { useHistory } from 'react-router-dom';
import usePersistedColumnWidths from 'hooks/usePersistedColumnWidths';
import useScrollManager from 'hooks/useScrollManager';

import {
  dateTimeFormat,
  formatDate,
  getUserFullName,
  cloneObj,
  getSeverityColor,
  fileDownload
} from 'helpers/AppHelpers';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import {
  getAssetTooltip,
  getClosedAtUtcTooltip,
  getDescriptionTooltip,
  getLocationTooltip,
  getOpenedAtUtcTooltip,
  getOpenedByUserTooltip,
  getReopenDateTooltip,
  getSeverityDisplayNameTooltip,
  getTicketRootCauseTooltip,
  getTicketTypeTooltip
} from 'helpers/TooltipFunctions';

import {
  LOCATION_DETAILED_PATH,
  TICKET_DETAILED_PATH,
  ASSET_DETAILED_PATH,
  TICKET_EDIT_PATH
} from 'constants/routeConstants';
import { TICKET_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';

import FilterModal from './Filter';

import {
  columnSizesConfig,
  generateChips,
  generateInitialQuery,
  parseQuery,
  removeChip,
  useTicketSummaryParams
} from './helpers';

import { createColumnHelper } from '@tanstack/react-table';
import { useCommonActions } from 'hooks/Common';

const columnHelper = createColumnHelper();

export default function TicketSummary() {
  const tableRef = useRef();
  const history = useHistory();
  const { calculateColumnWidths } = usePersistedColumnWidths(TICKET_LIST_TABLE_PARAMETER);
  const isMobile = useMobileViewport();

  const { isConfigReceived, getTableLimit, isAllLimitEnabled } = useUserConfig();

  const urlParams = useTicketSummaryParams();

  const { setTopPositionAction } = useCommonActions();
  const { filter, filterCriteria, counting } = useTicketSelector();
  const {
    getCountingAction,
    setFilterAction,
    getFilterCriteriaAction,
    getTicketListAction,
    getSummaryCsvResourceAction,
    clearStateAction
  } = useTicketActions();

  const [tableState, setTableState] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);

  const { filters, pagination, sortRules, responseReceived } = filter;

  const scrollTableToPosition = ({ table, container }) => {
    setTopPositionAction(container);
    tableRef.current.scrollTo({ top: table, behavior: 'smooth' });
  };

  const { saveScrollPosition } = useScrollManager({
    tableKey: TICKET_LIST_TABLE_PARAMETER,
    isDataLoaded: responseReceived && counting?.length,
    onScroll: scrollTableToPosition
  });

  const saveScroll = () => saveScrollPosition({ table: tableRef.current?.scrollTop });

  const getTickets = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      const payload = cloneObj(query);
      payload.filters.assetList = [];
      payload.filters.assignedUserList = [];

      getTicketListAction(parseQuery(payload)).then((tableData) => {
        !isAutoload && getCountingAction(payload.filters);
        setTickets(tableData, query, isAutoload);
      });
    },
    [setFilterAction, getTicketListAction, getCountingAction]
  );

  const setTickets = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data?.limit, page: data?.pageNumber, totalPages: data?.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((filterCriteria) => {
      const q = { filterCriteria, getTableLimit, isAllLimitEnabled, filter, ...urlParams };
      const resultFilter = generateInitialQuery(q);
      getTickets(resultFilter);

      const options = {
        ...filterCriteria,
        users: filters?.assignedUserList || [],
        assets: filters?.assetList || []
      };
      setChips(generateChips(options, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getTickets(getChangedBySearchFilter(filter, value)),
    [filter, getTickets]
  );
  const sortTable = useCallback(
    (rowName) => getTickets(getChangedBySortFilter(filter, rowName)),
    [filter, getTickets]
  );
  const applyFilter = useCallback(
    (values) => {
      getTickets(getChangedByApplyFilter(filter, values));

      const options = {
        ...filterCriteria,
        users: values?.assignedUserList || [],
        assets: values?.assetList || []
      };
      setChips(generateChips(options, values));
    },
    [filter, getTickets, filterCriteria]
  );
  const changeLimit = useCallback(
    () => getTickets(getChangedByLimitFilter(filter, getTableLimit(TICKET_LIST_TABLE_PARAMETER))),
    [filter, getTableLimit, getTickets]
  );
  const changePage = useCallback(
    (page, param) => getTickets(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getTickets]
  );
  const loadMore = useCallback(
    () => changePage(pagination.page + 1, 'AUTOLOAD'),
    [pagination, changePage]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip)),
    [applyFilter, filters]
  );

  const openCreateTicketModal = useCallback(() => history.push(TICKET_EDIT_PATH), []);

  const openFilterModal = useCallback(() => setOpenFilter(true), []);

  const columns = useMemo(() => {
    const customs = [];

    if (!filter?.filters?.isOpened) {
      customs.push('closedAtUtc');
    }
    if (filter?.filters?.reopenedFrom || filter?.filters?.reopenedTo) {
      customs.push('reopenDate');
    }

    const columnWidths = calculateColumnWidths(columnSizesConfig, customs);

    const defaultColumns = [
      columnHelper.accessor('id', {
        header: () => (
          <SortableHeader displayName="#" sortKey="id" sortRules={sortRules} onSort={sortTable} />
        ),
        cell: (props) => (
          <StyledLink bold to={`${TICKET_DETAILED_PATH}/${props.getValue()}`} onClick={saveScroll}>
            {props.getValue()}
          </StyledLink>
        ),
        size: columnWidths.id
      }),
      columnHelper.accessor('location', {
        header: () => (
          <SortableHeader
            displayName="Location"
            sortKey="location.siteCode"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: (props) => (
          <StyledLink
            isRedirectAvailable={!props.getValue().deleted}
            to={`${LOCATION_DETAILED_PATH}/${props.getValue().id}`}
            onClick={saveScroll}>
            {props.getValue().siteCode || ''}
          </StyledLink>
        ),
        tooltipValueGetter: getLocationTooltip,
        size: columnWidths.location
      }),
      columnHelper.accessor('asset', {
        header: () => (
          <SortableHeader
            displayName="Asset"
            sortKey="asset.drCode"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: (props) => (
          <StyledLink to={`${ASSET_DETAILED_PATH}/${props.getValue().id}`} onClick={saveScroll}>
            {props.getValue().drCode || ''}
          </StyledLink>
        ),
        tooltipValueGetter: getAssetTooltip,
        size: columnWidths.asset
      }),
      columnHelper.accessor('type', {
        header: () => (
          <SortableHeader
            displayName="Type"
            sortKey="type"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        tooltipValueGetter: getTicketTypeTooltip,
        size: columnWidths.type
      }),
      columnHelper.accessor('rootCause', {
        header: () => (
          <SortableHeader
            displayName="Root Cause"
            sortKey="rootCause"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        tooltipValueGetter: getTicketRootCauseTooltip,
        size: columnWidths.rootCause
      }),
      columnHelper.accessor('severity', {
        header: () => <SimpleHeader displayName="Severity" />,
        cell: (props) => <Indicator color={getSeverityColor(props.getValue().name)} />,
        tooltipValueGetter: getSeverityDisplayNameTooltip,
        centerContent: true,
        size: columnWidths.severity
      }),
      columnHelper.accessor('description', {
        header: () => (
          <SortableHeader
            displayName="Description"
            sortKey="description"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        tooltipValueGetter: getDescriptionTooltip,
        size: columnWidths.description
      }),
      columnHelper.accessor('openedByUser', {
        header: () => (
          <SortableHeader
            displayName="Opened by"
            sortKey="openedBy"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: (props) => getUserFullName(props.getValue()),
        tooltipValueGetter: getOpenedByUserTooltip,
        size: columnWidths.openedByUser
      }),
      columnHelper.accessor('openedAtUtc', {
        header: () => (
          <SortableHeader
            displayName="Opened on"
            sortKey="openedAtUtc"
            sortRules={sortRules}
            onSort={sortTable}
          />
        ),
        cell: (props) => (props.getValue() ? formatDate(props.getValue(), dateTimeFormat) : ''),
        tooltipValueGetter: getOpenedAtUtcTooltip,
        size: columnWidths.openedAtUtc
      })
    ];

    const customColumns = [];

    if (!filter?.filters?.isOpened) {
      customColumns.push(
        columnHelper.accessor('closedAtUtc', {
          header: () => (
            <SortableHeader
              displayName="Close Date"
              sortKey="closedAtUtc"
              sortRules={sortRules}
              onSort={sortTable}
            />
          ),
          cell: (props) => (props.getValue() ? formatDate(props.getValue(), dateTimeFormat) : ''),
          tooltipValueGetter: getClosedAtUtcTooltip,
          size: columnWidths.closedAtUtc
        })
      );
    }

    if (filter?.filters?.reopenedFrom || filter?.filters?.reopenedTo) {
      customColumns.push(
        columnHelper.accessor('reopenDate', {
          header: () => (
            <SortableHeader
              displayName="Reopen Date"
              sortKey="reopenDate"
              sortRules={sortRules}
              onSort={sortTable}
            />
          ),
          cell: (props) => (props.getValue() ? formatDate(props.getValue()) : ''),
          tooltipValueGetter: getReopenDateTooltip,
          size: columnWidths.reopenDate
        })
      );
    }

    return [...defaultColumns, ...customColumns];
  }, [sortTable, filter]);

  const downloadCsv = () =>
    getSummaryCsvResourceAction(filter).then((file) => file?.link && fileDownload(file));

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Ticket Summary">
          {!isMobile && (
            <div className={styles.controls}>
              <DownloadCSVButton onClick={downloadCsv} />
              <StyledButton
                label="Create ticket"
                onClick={openCreateTicketModal}
                classes={styles.main_button}
              />
            </div>
          )}
        </SummaryPageHeader>
        {isMobile && (
          <StyledButton
            label="Create ticket"
            onClick={openCreateTicketModal}
            classes={styles.main_button}
          />
        )}
      </SummaryHeaderWrapper>

      <SeverityCalculations counting={counting} />

      <SummaryTableControls>
        <FilterWrapper>
          <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
          {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
        </FilterWrapper>
        <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
      </SummaryTableControls>

      <DataTable
        columns={columns}
        data={tableState}
        isLoading={!responseReceived}
        isNoMatches={!!chips?.length || !!filters?.searchQuery}
        isMorePagesAvailable={pagination?.page < pagination?.totalPages}
        loadMore={loadMore}
        tableKey={TICKET_LIST_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      <SimplePagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={TICKET_LIST_TABLE_PARAMETER}
      />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
