import { useMobileViewport } from './useMobileViewport';

const columnWidthsCache = {};
const DEFAULT_COLUMN_WIDTH = 240;

const usePersistedColumnWidths = (tableKey) => {
  const isMobile = useMobileViewport();

  const getColumnWidths = () => {
    return columnWidthsCache[tableKey] || {};
  };

  const getColumnWidth = (key) => {
    return getColumnWidths()?.[key] || null;
  };

  const setColumnWidths = (newWidths) => {
    const updatedWidths = Object.keys(newWidths).reduce((acc, columnId) => {
      const width = newWidths[columnId];
      acc[columnId] = width < 52 ? 52 : width;
      return acc;
    }, {});

    columnWidthsCache[tableKey] = { ...columnWidthsCache[tableKey], ...updatedWidths };
  };

  const preprocessConfig = (config) => {
    const configEntries = Object.entries(config);

    const availableSpace = window.innerWidth - 180;
    const fixedWidthSum = configEntries.reduce((sum, [, column]) => {
      if (column.desktop && !column.percent) {
        return sum + column.desktop;
      }
      return sum;
    }, 0);
    const remainingSpace = Math.max(availableSpace - fixedWidthSum, 0);

    return { configEntries, remainingSpace };
  };

  const calculateColumnWidths = (config, customColumnNames = []) => {
    const { configEntries, remainingSpace } = preprocessConfig(config);

    const calculatePercent = (percent) => Math.max((remainingSpace * percent) / 100, 0);

    const columnWidths = configEntries.reduce((sizes, [columnName, column]) => {
      // to set mobile fixed value
      if (isMobile) {
        return { ...sizes, [columnName]: column.mobile };
      }
      // to set saved value
      if (getColumnWidth(columnName)) {
        return { ...sizes, [columnName]: getColumnWidth(columnName) };
      }
      // to set fixed value
      if (!column.percent) {
        return { ...sizes, [columnName]: column.desktop || DEFAULT_COLUMN_WIDTH };
      }
      // to calculate percent value
      if (column.percent) {
        return { ...sizes, [columnName]: calculatePercent(column.percent) };
      }
      return sizes;
    }, {});

    customColumnNames?.forEach((name) => {
      columnWidths[name] = getColumnWidth(name) || DEFAULT_COLUMN_WIDTH;
    });

    return columnWidths;
  };

  return {
    getColumnWidth,
    setColumnWidths,
    columnWidthsCache,
    calculateColumnWidths
  };
};

export default usePersistedColumnWidths;
