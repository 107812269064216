import React from 'react';
import styles from './Release.module.scss';

import { formatDate } from 'helpers/AppHelpers';

export function Release({ data }) {
  const { version, releaseDate, releaseNotes } = data;

  return (
    <div className={styles.release}>
      <div className={styles.release__header}>
        <div className={styles.release__version}>{version}</div>
        <div className={styles.release__date}>{releaseDate ? formatDate(releaseDate) : ''}</div>
      </div>

      <div className={styles.release__sections}>
        {releaseNotes.map(({ title, notes }, sIndex) => (
          <div key={`section-${sIndex}`} className={styles.release__section}>
            <div className={styles.release__section_title}>{title}</div>
            <ul className={styles.release__section_notes}>
              {notes.map((note, nIndex) => (
                <li key={`note-${nIndex}`} className={styles.release__section_note}>
                  {note}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
