import React from 'react';
import styles from './Controls.module.scss';

import { SidebarButton } from 'components/Buttons';
import StyledLink from 'components/StyledLink';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';
import { ReactComponent as QRReadIcon } from 'assets/images/qrRead.svg';

import { RELEASE_NOTES_PATH } from 'constants/routeConstants';

import projectData from '../../../../package.json';

export function Controls({ onQrClick, onLogout }) {
  const isMobile = useMobileViewport();

  return (
    <div className={styles.controls}>
      <SidebarButton label="QR for page" onClick={onQrClick}>
        <QRReadIcon />
      </SidebarButton>

      <SidebarButton label="Logout" onClick={onLogout}>
        <LogoutIcon />
      </SidebarButton>

      {open && isMobile && (
        <div className={styles.version}>
          <StyledLink to={RELEASE_NOTES_PATH} className={styles.version__label}>
            Version {projectData.version}
          </StyledLink>
        </div>
      )}
    </div>
  );
}
