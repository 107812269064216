import * as dashboard from './dashboard';
import * as asset from './asset';
import * as category from './category';
import * as prefix from './prefix';
import * as locations from './locations';
import * as project from './project';
import * as buildRouteMap from './buildRouteMap';
import * as reportList from './reportList';
import * as ticket from './ticket';
import * as user from './user';
import * as batchAssetUpdates from './batchAssetUpdates';
import * as auth from './auth';
import * as common from './common';
import * as looseItemDashboard from './looseItemDashboard';
import * as bomConfigurations from './bomConfigurations';
import * as assembleBOM from './assembleBOM';
import * as picklist from './picklists';
import * as leavelist from './leavelists';
import * as assetCalendar from './assetCalendar';
import * as teamMember from './teamMember';
import * as teamMemberTracker from './teamMemberTracker';
import * as importFilesPopup from './importFilesPopup';
import * as looseItem from './looseItem';
import * as overviewReport from './overviewReport';
import * as requestOrder from './requestOrder';
import * as employmentStatusEditor from './employmentStatusEditor';
import * as addLooseItemsPopup from './addLooseItemsPopup';
import * as billOfLading from './billOfLading';
import * as teamMemberHistory from './teamMemberHistory';
import * as addBom from './addBom';
import * as batchItemUpdates from './batchItemUpdates';
import * as looseItemCategory from './looseItemCategory';
import * as createBatchOfAssets from './createBatchOfAssets';
import * as search from './search';
import * as notificationCenter from './notificationCenter';
import * as userLogs from './userLogs';
import * as trainings from './trainings';
import * as ticketsDashboard from './ticketsDashboard';
import * as assetsDashboards from './assetsDashboards';
import * as manageLooseItems from './manageLooseItems';
import * as selectLooseItemsPopup from './selectLooseItemsPopup';
import * as ticketTypes from './ticketTypes';
import * as ticketRootCauses from './ticketRootCauses';
import * as teamMemberDashboard from './teamMemberDashboard';
import * as billOfLadingLogs from './billOfLadingLogs';
import * as addAssetsToPicklistPopup from './addAssetsToPicklistPopup';
import * as reportTemplates from './reportTemplates';
import * as setSchedulePopup from './setSchedulePopup';
import * as moveLooseItemsPopup from './moveLooseItemsPopup';
import * as qr from './qr';
import * as activeLocationsMap from './activeLocationsMap';
import * as appVersion from './appVersion';

export default {
  dashboard,
  asset,
  category,
  prefix,
  locations,
  buildRouteMap,
  project,
  reportList,
  ticket,
  user,
  batchAssetUpdates,
  auth,
  common,
  looseItemDashboard,
  bomConfigurations,
  assembleBOM,
  picklist,
  leavelist,
  assetCalendar,
  teamMember,
  teamMemberTracker,
  importFilesPopup,
  looseItem,
  overviewReport,
  requestOrder,
  employmentStatusEditor,
  addLooseItemsPopup,
  billOfLading,
  teamMemberHistory,
  addBom,
  batchItemUpdates,
  looseItemCategory,
  createBatchOfAssets,
  search,
  notificationCenter,
  userLogs,
  trainings,
  ticketsDashboard,
  assetsDashboards,
  manageLooseItems,
  selectLooseItemsPopup,
  ticketTypes,
  ticketRootCauses,
  teamMemberDashboard,
  billOfLadingLogs,
  addAssetsToPicklistPopup,
  reportTemplates,
  setSchedulePopup,
  moveLooseItemsPopup,
  qr,
  activeLocationsMap,
  appVersion
};
